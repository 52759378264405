
import {
    defineComponent,
    computed,
    ref,
    reactive,
    watch,
    nextTick,
    onMounted,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import scrollPane from "./ScrollPane.vue";
import { CloseBold, ArrowDown } from "@element-plus/icons-vue";
import Cache from "@/utils/cache";

export default defineComponent({
    components: {
        scrollPane,
        CloseBold,
        ArrowDown,
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const device = computed(() => store.state.app.device);
        //导航快捷列表
        const visitedViews = computed(() => store.state.tagsView.visitedViews);
        const items = computed(() => store.state.permissionMerchant.menuList);
        let userInfo = computed(() => store.getters.merchantUserInfo || Cache.getStorage(Cache.merchantKeyUser));
        const route = useRoute();
        let selectedTag = reactive<any>({});
        const isActive = (item) => {
            selectedTag.value = item;
            return item.path === route.path;
        };
        //快捷列表单个删除事件
        const closeSelectedTag = (item) => {
            store.dispatch("delVisitedViews", item).then((views) => {
                if (isActive(item)) {
                    const latestView = views.slice(-1)[0];
                    if (latestView) {
                        router.push(latestView.path);
                    } else {
                        router.push("/");
                    }
                }
            });
        };
        //关闭标签
        let visible = ref<any>(false);
        watch(visible, (newValue) => {
            if (newValue) {
                document.body.addEventListener("click", closeLabel);
            } else {
                document.body.removeEventListener("click", closeLabel);
            }
        });
        //操作标签
        const changeLabel = () => {
            visible.value = !visible.value;
        };
        const closeLabel = () => {
            visible.value = false;
        };
        //关闭其他页
        const closeOthers = () => {
            // router.push(selectedTag)
            store.dispatch("delOthersViews", selectedTag).then((item) => {
                console.log(item, "item");
            });
        };
        //关闭全部
        const closeAll = () => {
            store.dispatch("delAllViews");
            store.dispatch("permissionMerchant/levelDevice", {});
            router.push("/");
        };
        const navTouch = (item) => {
            store.dispatch("permissionMerchant/levelDevice", {});
            router.push(item);
        };
        const navbarTouch = (e) => {
            items.value.forEach((item) => {
                if (item.path == e.path) {
                    store.dispatch("permissionMerchant/levelDevice", item);
                    return;
                } else {
                    if (item.children && item.children.length > 0) {
                        item.children.forEach((e2) => {
                            if (e2.path == e.path) {
                                store.dispatch("permissionMerchant/levelDevice", item);
                                return;
                            } else {
                                if (e2.children && e2.children.length > 0) {
                                    e2.children.forEach((e3) => {
                                        if (e3.path == e.path) {
                                            store.dispatch("permissionMerchant/levelDevice", item);
                                            return;
                                        }
                                    });
                                }
                            }
                        });
                    }
                }
            });
            router.push(e.path);
        };
        onMounted(() => {});
        return {
            device,
            visitedViews,
            isActive,
            closeSelectedTag,
            closeOthers,
            closeAll,
            visible,
            changeLabel,
            selectedTag,
            navTouch,
            navbarTouch,
            userInfo,
        };
    },
});
