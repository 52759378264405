const getters: any = {
  device: state => state.app.device,
  menuList: state => state.permissionMerchant.menuList,
  // 数据字典
  dictDatas: state => state.dict.dictDatas,
  permission_routes: state => state.permissionMerchant.routes,
  merchantUserList: state => state.permissionMerchant.merchantUserList,
  // token
  token: state => state.userMerchant.token,
  // 用户信息
  merchantUserInfo: state => state.userMerchant.merchantUserInfo,
  // 权限集
  btnPermissions: state => state.permissionMerchant.btnPermissions,
}
export default getters
