import * as CategoryApi from '@/api/diet/category'
// import https from '../../utils/http'

const state  = {
    categories: null,
    mcCategories: null,
}

const mutations = {
    SET_CATEGORIES: (state:any, data:any) => {
        state.categories = data
    },
    SET_MERCHANT_CATEGORIES: (state, data) => {
        state.mcCategories = data
    },
}

const actions = {
    setCategories({ commit }, data:any) {
        commit('SET_CATEGORIES', data)
    },
    getCategories({ commit, state }) {
        return new Promise((resolve, reject) => {
            if (!state.categories) {
                CategoryApi.treelist().then((res:any) => {
                    commit('SET_CATEGORIES', res)
                    resolve(res)
                })
                // https.get("/category/treelist", {}, false).then((res: any) => {
                //     commit('SET_CATEGORIES', res)
                //     resolve(res)
                // })
            }else{
                resolve(state.categories)
            }
        })
    },
    setMerchantCategories({ commit }, data:any) {
        commit('SET_MERCHANT_CATEGORIES', data)
    },
    getMerchantCategories({ commit, state }) {
        return new Promise((resolve, reject) => {
            if (!state.mcCategories) {
                CategoryApi.ptreelist().then((res:any) => {
                    commit('SET_MERCHANT_CATEGORIES', res)
                    resolve(res)
                })
            }else{
                resolve(state.mcCategories)
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

