import https from '../../utils/http'
import store from "@/store";
import Cache from "@/utils/cache";
const state: any = {
  merchantUserInfo: null,
  token: '',
}

const mutations = {
  //set token
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  //clear token
  CLEAR_TOKEN: (state) => {
    state.token = ''
  },
  SET_USER_INFO: (state, merchantUserInfo) => {
    state.merchantUserInfo = merchantUserInfo
  },
  CLEAR_USER_INFO: (state) => {
    state.merchantUserInfo = null
  }
}

const actions = {
  // user login
  login({commit}, merchantUserInfo: any) {
    return new Promise((resolve, reject) => {
      https.post("/passport/login", merchantUserInfo, false).then((res: any) => {
        Cache.setCookie(Cache.keyToken, res.token, null);
        commit('SET_TOKEN', res.token)
        resolve(res)
      }).catch((err: any) => {
        reject(err)
      });
    })
  },
  // user logout
  logout({commit}, state: any) {
    return new Promise((resolve, reject) => {
      https.post("/passport/logout", {}, false).then((res: any) => {
        store.dispatch('permissionMerchant/clearPermission');
        store.dispatch('permissionMerchant/clearMerchantUserList');
        store.dispatch('dict/clearDictDatas');
        store.dispatch('permissionMerchant/menuDel');
        Cache.setStorage(Cache.merchantKeyUser, null);
        Cache.setCookie(Cache.keyToken, null, null);
        Cache.setStorage(Cache.keyMerchantList, null)
        commit('CLEAR_TOKEN')
        commit('CLEAR_USER_INFO')
        resolve('ok')
        window.location.href = `${location.origin}/login.html`
      }).catch((err: any) => {
        reject(err)
      });
    })
  },
  // 通用跳转至登录页
  toLogin({commit}, state: any) {
    window.location.href = `${location.origin}/login.html`;
  },
  // 通用跳转至登录页
  toJoin({commit}, merchantId: any) {
    window.location.href = `${location.origin}/join.html`;
  },
  // 通用跳转至首页
  toHome({commit}, state: any) {
    window.location.href = `${location.origin}/`;
  },
  // 通用跳转至协议
  toDeal({commit}, state: any) {
    window.open(`${location.origin}/deal.html`);
  },
  // 获取用户信息
  getInfo({commit, state}) {
    return new Promise((resolve, reject) => {
      https.get("/passport/getInfo", {}, false,null).then((res: any) => {
        Cache.setStorage(Cache.merchantKeyUser, res);
        commit('SET_USER_INFO', res)
        resolve(res)
      }).catch((err: any) => {
        reject(err)
      });
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
