import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Layout from '@/layout/index.vue'
import Cache from '../utils/cache'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
    {
        name: 'index',
        path: '/',
        redirect: '/home/dashboard',
        component: Layout,
        children: [{
            name: 'homeDashboard',
            path: '/home/dashboard',
            component: () => import('@/views/home/dashboard.vue'),
            meta: {
                title: '后台管理系统',
                icon: 's-home',
                color: '#fd9d21',
                keepAlive: true
            }
        }
        ]
    },
    {
        path: "/home/operatingManual",
        component: Layout,
        children: [

            {
                path: '/home/operatingManual',
                name: 'operatingManual',
                component: () => import('@/views/home/operatingManual.vue'),
                meta: {
                    title: '操作手册',
                }
            }
        ]
    },
    {
        path: "/goodsAdmin",
        component: Layout,
        children: [

            {
                path: '/goodsAdmin/uploadGoodsList',
                name: 'uploadGoodsList',
                component: () => import('@/views/goodsAdmin/uploadGoodsList.vue'),
                meta: {
                    title: '商品列表',
                }
            }
        ]
    },
    {
        path: "/storeAdmin/accountInfo",
        component: Layout,
        children: [
            {
                path: '/storeAdmin/accountInformation',
                name: 'accountInfo',
                component: () => import('@/views/storeAdmin/accountInformation.vue'),
                meta: {
                    title: '工行账户',
                }
            },
            {
                path: '/storeAdmin/weChatApply',
                name: 'weChatApply',
                component: () => import('@/views/storeAdmin/weChatApply.vue'),
                meta: {
                    title: '收付通账户',
                }
            },
            {
                path: '/storeAdmin/weChatAccount',
                name: 'weChatAccount',
                component: () => import('@/views/storeAdmin/weChatAccount.vue'),
                meta: {
                    title: '账户信息',
                }
            },
            {
                path: '/financeAdmin/accountInformation',
                name: 'accountInformation',
                component: () => import('@/views/financeAdmin/accountInformation.vue'),
                meta: {
                    title: '普通账户',
                }
            }
        ]
    },
    {
        path: "/examine",
        component: Layout,
        children: [
            {
                path: '/examineAdmin/examineTemplate',
                name: 'examineTemplate',
                component: () => import('@/views/examineAdmin/examineTemplate.vue'),
                meta: {
                    title: '考核模板',
                }
            },
            {
                path: '/examineAdmin/examineRecordList',
                name: 'examineRecordList',
                component: () => import('@/views/examineAdmin/examineRecordList.vue'),
                meta: {
                    title: '考核记录',
                }
            },
            {
                path: '/examineAdmin/performanceExamineTemplate',
                name: 'performanceExamineTemplate',
                component: () => import('@/views/examineAdmin/performanceExamineTemplate.vue'),
                meta: {
                    title: '配置模板',
                }
            },
        ]
    },
    {
        path: '/login.html',
        name: 'login',
        component: () => import('@/views/passport/login.vue'),
        meta: {
            title: '登录',
        }
    },
    {
        path: '/forgotPwd.html',
        name: 'forgotPwd',
        component: () => import('@/views/passport/forgotPwd.vue'),
        meta: {
            title: '找回密码',
        }
    },
    {
        path: '/deal.html',
        name: 'deal',
        component: () => import('@/views/passport/deal.vue'),
        meta: {
            title: '本家协议',
        }
    },
    {
        path: '/join.html',
        name: 'join',
        component: () => import('@/views/passport/join.vue'),
        meta: {
            title: '加入我们',
            keepAlive: true
        },
    },
]

const router = createRouter({
    history: createWebHistory('/'),
    routes
})

const whiteList = ['/login.html', '/forgotPwd.html', '/deal.html', '/join.html']; //白名单

router.beforeEach(async (to, from, next) => {
    let accessToken = store.getters.token || Cache.getCookie(Cache.keyToken)
    //当前路由
    //判断标题
    document.title = to.meta.title ? String(to.meta.title) : '商家管理后台';
    const toPath: string = to.path;
    if (accessToken) {
        let user = store.getters.merchantUserInfo || Cache.getStorage(Cache.merchantKeyUser);
        if(!store.getters.dictDatas || Object.keys(store.getters.dictDatas).length === 0){
            await store.dispatch('dict/loadDictDatas')
        }
        if (toPath === '/login.html') {
            next({path: '/'})
        } else if (!user || !user.id) {
            // 取不到用户就退出登录
            await store.dispatch("userMerchant/getInfo").then((res) => {
                if(!res.role || res.role == 1 || res.role == 3) {
                    if(toPath !='/join.html'){
                        next(`/join.html`)
                    }
                }else{
                    next('/')
                }
            });
        } else if (toPath != '/join.html' && (store.getters.menuList.length<=0 || !router.hasRoute(to.name))) {
            await store.dispatch('permissionMerchant/merchantMenuAdd').then(accessedRoutes => {
                next({
                    ...to,
                    replace: true
                })
                return;
            })
        } else {
            next()
        }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            // next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
            // window.location.href="/login.html"
            next(`/login.html`)
        }
    }
})

export default router
