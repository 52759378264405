const state: any = {
        visitedViews: [{
                elementType: "goodsList",
                goodsList: [], //商品列表
                abilitySet: {
                        //布局设置项
                        layoutType: 1, //布局类型
                        name: "", //内容
                        title: "", //标题
                        url: "", //跳转连接
                        imgUrl: "",
                        textAlign: "", //居中方式
                        showBrief: 1,//商品描述展示
                        showDiscountPrice: 1,//划线价展示
                },
                listStyle: {
                        //列表样式
                        marginTop: 0, //上边距
                        marginBottom: 0, //下边距
                        marginLeft: 0,//左右边距
                        bgColor: "#f5f5f5", //背景,
                        borderRadius: 0, //背景圆角
                        width: 0, //宽度
                        height: 0, //高度
                        imgPadding: 0, //图片间隔
                        imgBorderRadius: 0, //图片圆角
                        fontSize: 0, //文字大小
                        color: "", //图片颜色
                        activeColor: "", //选中的字体颜色,
                        chunkColor: "", //滑块颜色
                        bgHeight: 0, //背景高度
                        textAlign: "", //文本位置
                        inputBgColor: "", //input的背景色
                        inputHeight: "", //input高度
                        titleColor: "", //标题字体颜色
                        showRadius: false, //是否有圆角,true、false
                        autoplay: 0, //轮播图播放时间 单位秒
                        autoplayShow: false, //轮播图是否自动播放
                        loop: true, //是否开启循环播放
                        iconUrl: "", //图标路径
                },
                goodsStyle: {
                        //商品css样式
                        marginTop: 0, //上边距
                        marginBottom: 8, //下边距
                        paddingTop: 8, //上内边距
                        paddingBottom: 8, //下内边距
                        paddingLeft: 8, //左内边距
                        paddingRight: 8, //右内边距
                        bgColor: "#ffffff", //背景,
                        borderRadius: 8, //背景圆角
                        width: 0,//宽度
                        height: 104,//高度
                        imgBorderRadius: 6, //图片圆角
                        fontSize: 0, //文字大小
                        color: "", //图片颜色
                        activeColor: "", //选中的字体颜色,
                        chunkColor: "", //滑块颜色
                        bgHeight: 0, //背景高度
                        textAlign: "", //文本位置
                        inputBgColor: "", //input的背景色
                        inputHeight: "", //input高度
                        titleColor: "", //标题字体颜色
                        showRadius: false, //是否有圆角,true、false
                        autoplay: 0, //轮播图播放时间 单位秒
                        autoplayShow: false, //轮播图是否自动播放
                        loop: true, //是否开启循环播放
                        iconUrl: "", //图标路径
                        buttonColor: '',//按钮颜色
                },
        },],
}

const mutations = {
        CHANGE_LAYOUT_TYPE: (state, view) => {//商品列表布局类型切换
                let a = view.value
                state.visitedViews.find((i) => i.elementType == view.elementType).abilitySet.layoutType = view.value
        },
}
const actions = {
        changeLayoutType({ commit }, view) {
                commit('CHANGE_LAYOUT_TYPE', view)
        },
}

export default {
        namespaced: true,
        state,
        mutations,
        actions
}