
/**
 * @description 侧边菜单栏页面
 */
import { computed, onMounted, ref, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Grid, ArrowRight } from "@element-plus/icons-vue";
import Cache from "@/utils/cache";

export default {
    components: {
        Grid,
        ArrowRight,
    },
    setup() {
        let userInfo = computed(() => store.getters.merchantUserInfo || Cache.getStorage(Cache.merchantKeyUser));
        const route = useRoute();
        const convertIcon = () => {
            return `wefwerwer`;
        };
        const router = useRouter();
        const store = useStore();
        /**
         * @description 动态路由采用 curItens
        */
        //总导航列表数据
        const items = computed(() => store.state.permissionMerchant.menuList);
        const name = ref<String>("");
        const collapse = computed(() => store.state.collapse);
        // 监听菜单栏的值变化
        const menuList = computed(() => store.state.permissionMerchant.menuLevel);
        const menuShow = computed(() => store.state.permissionMerchant.menuShape);
        //数据导航菜单变化
        watch(menuList,(newVal, oldVal) => {
                name.value = newVal.name;
            },
            { immediate: true, deep: true }
        );
        //菜单点击回调
        const sidebarTouch = (item) => {
            if (item.children[0].children && item.children[0].children.length > 0) {
                router.push(item.children[0].children[0].path);
                //存储到vuex
                store.dispatch("addVisitedViews", item.children[0].children[0]);
            } else {
                store.dispatch("addVisitedViews", item.children[0]);
                router.push(item.children[0].path);
            }
            name.value = item.name;
            store.dispatch("permissionMerchant/levelDevice", item);
        };
        //菜单点击回调
        const menuTouch = (item, info) => {
            store.dispatch("addVisitedViews", item);
            router.push(item.path);
            name.value = info.name;
            store.dispatch("permissionMerchant/levelDevice", info);
        };
        watch(() => items.value, (newItems) => {
			nextTick();
			if (newItems && newItems.length > 0) {
				const firstItem = newItems[0];
				// 在这里判断是否需要执行 sidebarTouch
				const shouldExecuteSidebarTouch = userInfo.value && userInfo.value.isChannelMerchant !== 0;
				if (shouldExecuteSidebarTouch) {
					sidebarTouch(firstItem);
				} else {
					// 渠道商页面处理逻辑
					switch (route.path) {
						case "/home/dashboard":
							router.push("/");
							break;
						case "/channelMerchantsAdmin/promotionStatistics":
							router.push("/");
							break;
						case "/channelMerchantsAdmin/accountAdmin":
							router.push("/");
							break;
						case "/channelMerchantsAdmin/myChannelCount":
							router.push("/");
							break;
						default:
							router.push(route.path);
							break;
					}
				}
			}
		}, { immediate: true });
        const withoutTouch = () => {
             window.open('https://m.bjftyx.net/auxiliaryAdmin/login.html', '_blank');
        }
        return {
            items,
            name,
            menuShow,
            collapse,
            convertIcon,
            sidebarTouch,
            menuTouch,
            withoutTouch
        };
    },
    methods: {},
};
