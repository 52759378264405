
/**
 * @description 侧边菜单栏页面
 */
import { computed, watch, reactive, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import {
    Calendar,
    AlarmClock,
    Brush,
    Fold,
    Menu,
    ArrowDownBold,
} from "@element-plus/icons-vue";
export default {
    components: {
        Calendar,
        AlarmClock,
        Brush,
        Fold,
        Menu,
        ArrowDownBold,
    },
    setup() {
        let showMenu = ref<any>(false);
        const showBtn = () => {
            showMenu.value = !showMenu.value;
        };
        let currentStep = ref<any>(0);
        const convertIcon = () => {
            return `wefwerwer`;
        };

        /**
         * @description 动态路由采用 curItens
        */
        //导航菜单数据
        const menuItems = reactive<any>({});
        //路由
        const route = useRoute();
        const menuShow = computed(
            () => store.state.permissionMerchant.menuShape
        );

        //页面刷新的时候,是哪个路由就active对应菜单
        const onRoutes = computed(() => {
            return route.path;
        });
        //vuex
        const store = useStore();
        // 监听菜单栏的值变化
        const menuList = computed(
            () => store.state.permissionMerchant.menuLevel
        );
        //菜单点击回调
        const menuTouch = (item) => {
            store.dispatch("addVisitedViews", item);
        };
        //数据导航菜单变化
        watch(menuList,(newVal, oldVal) => {
				menuItems.value = newVal;
                if (JSON.stringify(menuItems.value) != "{}") {
                    showMenu.value = true;
                }
            },
            { immediate: true, deep: true }
        );
        const handleMenuSelect = () => {};
        return {
            menuItems,
            route,
            onRoutes,
            convertIcon,
            menuShow,
            menuTouch,
            currentStep,
            handleMenuSelect,
            showMenu,
            showBtn,
        };
    },
    methods: {},
};
