import axios, {AxiosRequestConfig} from 'axios';
import { ElMessage } from 'element-plus';
import store from '../store'
import { useRouter } from "vue-router";
import Cache from './cache'
import Config from '../config/config'
//loading
import { showLoading, hideLoading } from "@/utils/loading";
const router = useRouter();

// 创建axios实例
const service = axios.create({
	baseURL: Config.merchantPortUrl,
	timeout: 15000,
	// withCredentials: true
})
const errorHandler = (err) => {
	Promise.reject(err).catch(() => {
		if (err) {
			switch (err.code) {
				case "02001":
					store.dispatch('userMerchant/logout')
					break;
				default:
					ElMessage.error(err.message || '系统错误')
					break
			}
		}
	})
}
/**
 * @desc 请求头封装
 */
service.interceptors.request.use(config => {
	// 请求拦截进来调⽤显⽰loading效果
	config.headers["X-Requested-With"] = "XMLHttpRequest"
	// let uuid = Cache.getCookie(Cache.keyUuid)
	// if (uuid) {
	// 	config.headers[Cache.keyUuid] = uuid
	// }
	/** 设置令牌 */
	let accessToken = store.getters.token || Cache.getCookie(Cache.keyToken)
	if (accessToken) {
		config.headers['Authorization'] = accessToken
	}
	const isPutPost = config.method === 'put' || config.method === 'post'
	const isjson = config.headers['Content-Type'] === 'application/json'
	if (isPutPost) {
		if (isjson) {
			config.data = JSON.stringify(config.data)
		} else {
			if (!(config.data instanceof FormData)) {
				config.data = getFormData(config.data)
			}
		}
	} else {
		formatParams(config.params)
	}

	showLoading();
	if (config.method == 'get') {
		config.params = {
			...config.params,
			...{ '_t': new Date().getTime() }
		}
	}
	return config
}, error => {
	Promise.reject(error)
});

const formatParams = function (params) {
	for (let name in params) {
		if (params[name] instanceof Date) {
			params[name] = params[name].getTime()
		}
	}
	return params
}

const getFormData = function (data:any) {
	var buildParams = function (prefix, obj, add) {
		if (obj instanceof Array) {
			// Serialize array item.
			obj.forEach(function (v, i) {
				if (/\[\]$/.test(prefix)) {
					// Treat each array item as a scalar.
					add(prefix, v);
				} else {
					buildParams(prefix + (typeof v === 'object' ? '[' + i + ']' : ''), v, add);
				}
			});

		} else if (obj instanceof Date) {
			add(prefix, obj.getTime());
		} else if (obj != null && typeof obj === 'object') {
			// Serialize object item.
			for (var name in obj) {
				buildParams(prefix + "." + name, obj[name], add);
			}
		} else {
			// Serialize scalar item.
			add(prefix, obj);
		}
	};
	var formData = new FormData();
	var append = function (key, value) {
		value = (typeof (value) == 'function') ? value() : value;
		if (value !== undefined && value !== null) {
			formData.append(key, value)
		}
	};

	// If an array was passed in, assume that it is an array of form elements.
	if (data instanceof Array) {
		// Serialize the form elements
		data.forEach(function (item, i) {
			buildParams('[' + i + ']', item, append);
		});
	} else {
		for (var prefix in data) {
			buildParams(prefix, data[prefix], append);
		}
	}
	return formData;
}

/**
 * @desc 请求返回结果封装
 */
service.interceptors.response.use(
	async response => {
		// 响应拦截进来隐藏loading效果，此处采⽤延时处理是合并loading请求效果，避免多次请求loading关闭⼜开启
		setTimeout(() => {
			hideLoading();
		}, 200);
		if(response.status === 200){
			if(response.config.responseType=='blob'){
				return Promise.resolve(response)
			}
			if (response.data.code == "000") {
				return Promise.resolve(response.data.data)
			}
			if(response.data.code == "44444"){
				return Promise.resolve(response.data)
			}
		}
		let err = {
			code: response.data.code,
			message: response.data.message
		}

		errorHandler(err)
		return Promise.reject(err)
	},
	async error => {
		// 响应拦截进来隐藏loading效果，此处采⽤延时处理是合并loading请求效果，避免多次请求loading关闭⼜开启
		setTimeout(() => {
			hideLoading();
		}, 200);
		errorHandler({
			code: -1,
			message: "网络错误"
		})
		return Promise.reject(error)
	}
);
/**
 * 关闭全局加载
 * 延迟200毫秒关闭，以免晃眼睛
 * @param target
 */
const closeLoading = (target:any) => {
	if (!target || !target.config || !target.config.loading) return true
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			target.config.loading.close()
			// resolve()
		}, 200)
	})
}
const getUrl = (url) => {
	if (url.indexOf("//") < 0) {
		url = Config.merchantPortUrl + url
	}
	return url
}
const jsonp = (url) => {
	return new Promise((resolve) => {
		(window as any).jsonCallBack = (result) => {
		    resolve(result)
		}
		var script = document.createElement('script')
		script.type = 'text/javascript'
		script.src = `${url}&callback=jsonCallBack`
		document.getElementsByTagName('head')[0].appendChild(script)
		setTimeout(() => {
			document.getElementsByTagName('head')[0].removeChild(script)
		}, 500)
	})
}

const get = (url:any, query:any, options:any,timeout:any) => {
	return service.get(getUrl(url), Object.assign({
		params: query && query.params || query,
		timeout:timeout==0?timeout:15000
	}, options))
}

const post = (url:any, query:any, options:any) => {
	return service.post(getUrl(url), query, options)
}
/**
 * download方法
 * @param {String} method [请求的方式]
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {String} fileType [导出文件类型] 默认值 xls
 * @param {String} fileName [导出文件名称] 默认值 导出文件
 */
const download = (method:'get'|'post', url:any, params:any, fileType:any, fileName:any) => {
	fileType = fileType || ' ';
	fileName = fileName || '导出文件';
	let config:AxiosRequestConfig = {
		url: url,
		method: method,
		// headers和responseType一定要在一起设置，否则会导致 获取到的二进制文件流 格式不正确
		headers: {
			'content-disposition': "attachment;filename=" + fileType,
			'Content-Type': 'application/json'
		},
		responseType: 'blob' // 设置请求数据格式
	};
	if(method === 'get'){
		config.params = params
	}else{
		config.data = params
	}
	return new Promise((resolve, reject) => {
		service.request(config)
			.then(res => {
				resolve(res.data);
				if (!res) {
					return
				}
				let url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
				let link = document.createElement('a');
				link.style.display = 'none';
				link.href = url;
				link.setAttribute('download', `${fileName}.${fileType}`);
				document.body.appendChild(link);
				link.click();
			})
			.catch(err => {
				console.log(err)
				reject(err.data);
			})
	});
}

export default {
	get,
	post,
	jsonp,
	download
}
