import { createStore } from 'vuex'
import getters from './getters'
import tagsView from './tagsView'
import merchantTheme from './modules/merchantTheme'
import permissionMerchant from './modules/permissionMerchant'
import app from './modules/app'
import userMerchant from './modules/userMerchant'
import dict from './modules/dict'
import category from './modules/category'
// 本地存储解决刷新数据小时问题
import createPersistedState from "vuex-persistedstate"

const store = createStore({
	modules: {
		app,
		tagsView,
		permissionMerchant,
		userMerchant,
		dict,
		category,
		merchantTheme
	},
	getters,
	plugins: [
		createPersistedState({
			// 也可以是sessionstorage
			storage: window.localStorage,
			// tagsView: tagsView,
			paths: ['tagsView', 'app', 'permissionMerchant', 'userMerchant', 'dict']
		})
	]
})
export default store;
