var validateList = {
    IsMobile(value) {
        var exp = /^1\d{10}$/;
        return exp.test(value);
    },
    IsMoney(value) {
        if ((value - 0) === 0) {
            return true;
        }
        var exp = /^[1-9]{1}\d*(.\d{1,2})?$|^0.\d{1,2}$|^0$/;
        return exp.test(value);
    },
    IsPercentage(value) {
        var exp = /^(\d{1,2}(\.\d{1,2})?|100)$/;
        return exp.test(value);
    },
    IsInt(value) {
        var exp = /^([1-9]\d*|[0]{1,1})$/;
        return exp.test(value);
    },
    //用户名正则，4到16位（字母，数字，下划线，减号）
    IsPassword(value){
        var exp = /^[a-zA-Z0-9_-]{6,20}$/;
        return exp.test(value);
    }
}
export const validate = validateList;
