import Http from "@/utils/http";


// 查询全部字典数据列表
export function listSimpleDictDatas(query) {
  return Http.get('/dict/data/getListAll', query)
}

export function getDicts(dictType) {
  return Http.get('/dict/data/type/' + dictType)
}

